<template>
  <v-container fluid class="pa-0">
    <v-card class="py-6 px-6 mt-3" rounded="lg" elevation="0" width="100%">
      <v-row>
        <v-col flex>
          <div class="back-to-projects" @click="handleBackClick">
            <v-icon color="blue">mdi-chevron-left</v-icon>
            <p v-if="showAddUsersView" class="d-flex-inline justify-center align-center ma-0 blue--text font-weight-bold">
              {{ $t('projects.create_project.back_to_project_info') }}
            </p>
            <p v-else class="d-flex-inline justify-center align-center ma-0 blue--text font-weight-bold">
              {{ $t('projects.create_project.back_to_projects') }}
            </p>
          </div>
        </v-col>
        <v-col v-if="showAddUsersView" cols="auto" class="pointer" @click="closeShowAddUsersView">
          <v-icon>mdi-close</v-icon>
        </v-col>
      </v-row>
      <template v-if="showAddUsersView">
        <p class="text-start display-1 font-weight-bold my-2">{{ $t('projectUsers') }}</p>
        <div class="mt-6 d-flex">
          <v-chip
            @click="changeFilter('all')"
            :class="{ 'blue--text': tableFilter === 'all' }"
            width="200px"
            :color="tableFilter === 'all' ? 'blue-light' : 'gray-light'"
            label
          >
            <div class="font-weight-bold px-2">
              {{ $t('all') }} <span class="ml-2">{{ users.length - selectedUserLength }}</span>
            </div>
          </v-chip>
          <div class="ml-2">
            <v-chip
              @click="changeFilter('selected')"
              :class="{ 'blue--text': tableFilter === 'selected' }"
              width="200px"
              :color="tableFilter === 'selected' ? 'blue-light' : 'gray-light'"
              label
            >
              <div class="font-weight-bold px-2">
                {{ $t('selected') }} <span class="ml-2">{{ selectedUserLength }}</span>
              </div>
            </v-chip>
          </div>
        </div>
        <v-row class="d-flex align-center justify-space-between mt-5">
          <v-col :cols="$vuetify.breakpoint.smAndUp ? 'auto' : 12" class="d-flex align-center">
            <v-text-field
              v-model="search"
              type="text"
              color="blue"
              prepend-inner-icon="mdi-magnify"
              placeholder="Search by name, email, etc."
              class="search_input"
              dense
              single-line
              filled
              hide-details
              clear-icon="body-2"
            ></v-text-field>
          </v-col>
          <v-col flex class="d-flex justify-space-between align-center">
            <ProjectUserFilter @filters="applyFilters" />
            <v-sheet
              color="gray-100"
              width="50px"
              class="d-flex ml-4 align-center justify-center"
              height="50px"
              rounded="lg"
              v-on="on"
            >
              <v-icon color="gray-ish">mdi-cog-outline</v-icon>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row v-if="isAnyFilterApplied" class="mt-3">
          <v-chip
            color="gray-light"
            class="ma-2 gray-ish--text font-weight-semi-bold"
            v-for="role in this.appliedFilters.roles"
            :key="role.id"
            label
          >
            {{ $t('role') }}: {{ role.name }}
          </v-chip>
          <v-chip
            color="gray-light"
            class="ma-2 gray-ish--text font-weight-semi-bold"
            v-for="project in this.appliedFilters.projects"
            :key="project.id"
            label
          >
            {{ $t('project') }}: {{ project.name }}
          </v-chip>
          <v-chip
            color="gray-light"
            class="ma-2 gray-ish--text font-weight-semi-bold"
            v-for="tag in this.appliedFilters.tags"
            :key="tag.id"
            label
          >
            {{ $t('project') }}: {{ tag.title }}
          </v-chip>
          <v-btn class="text-capitalize mt-1" color="blue" text @click="clearAll">{{ $t('clearAll') }}</v-btn>
        </v-row>
        <ProjectUserTable :usersData="users" :tableFilter="tableFilter" @updateUser="updateUser" />
      </template>
      <template v-else>
        <v-row justify="center">
          <v-col cols="12" md="5">
            <h2 class="text-start">{{ $t('projects.create_project.title') }}</h2>
          </v-col>
        </v-row>
        <v-form role="updateProfileForm" @submit.prevent="handleSubmit(updateProfile)">
          <v-row justify="center" class="my-6">
            <v-col cols="12" md="5">
              <v-hover v-slot="{ hover }">
                <div
                  class="project-logo"
                  @click="openFileDialog"
                  @drop.prevent="handleDrop"
                  @dragover.prevent
                  @dragenter.prevent
                  :style="imageStyle"
                  :class="{ hovering: hover }"
                >
                  <input
                    type="file"
                    ref="fileInput"
                    @change="handleFileChange"
                    accept="image/png, image/jpeg"
                    style="display: none"
                  />
                  <template v-if="!imageSrc">
                    <p class="ma-0">{{ $t('projects.create_project.drag_and_drop.part1') }}</p>
                    <p class="ma-0">{{ $t('projects.create_project.drag_and_drop.part2') }}</p>
                    <p class="ma-0 font-weight-bold mt-2 blue--text">
                      {{ $t('projects.create_project.drag_and_drop.part3') }}
                    </p>
                  </template>
                  <template v-if="hover && imageSrc">
                    <v-icon class="edit-icon" @click.stop="openFileDialog"> mdi-pencil </v-icon>
                    <v-icon class="delete-icon" @click.stop="removeImage"> mdi-delete </v-icon>
                  </template>
                </div>
              </v-hover>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" md="5">
              <p class="d-flex ma-0 mb-2 font-weight-medium body-2">
                {{ $t('projects.create_project.project_name') }}*
              </p>
              <v-text-field
                v-model="form.projectName"
                type="text"
                dense
                single-line
                filled
                placeholder="Project Name"
                :rules="[rules.required]"
                clear-icon="body-2"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col cols="12" md="5">
              <p class="d-flex ma-0 mb-2 font-weight-medium body-2">
                {{ $t('projects.create_project.project_key') }}*
              </p>
              <v-text-field
                v-model="form.projectKey"
                type="text"
                dense
                single-line
                filled
                :placeholder="$t('projects.create_project.project_key') "
                :rules="[rules.required, rules.min2Chars, rules.projectKeyMax10Chars, rules.projectKeyFormat]"
                clear-icon="body-2"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col cols="12" md="5">
              <p class="d-flex ma-0 mb-2 font-weight-medium body-2">{{ $t('projects.create_project.description') }}</p>
              <v-text-field
                v-model="form.description"
                type="text"
                dense
                single-line
                filled
                placeholder="Description"
                hide-details
                clear-icon="body-2"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <v-row justify="end" class="mt-4">
        <v-col cols="12">
          <v-flex class="mt-6 d-sm-flex justify-end">
            <!--
            <v-btn
              v-if="!showAddUsersView"
              dark
              large
              color="gray-100"
              class="text-capitalize font-weight-bold black--text mr-4 mt-2"
              :width="$vuetify.breakpoint.smAndDown ? '100%' : '150xp'"
              @click="showAddUsersView = true"
            >
              <v-icon class="mr-1" size="16">mdi-plus</v-icon> {{ $t('projects.create_project.add_users') }}
            </v-btn>
            <v-btn
              v-if="showAddUsersView && toBeSelectedUserLength && tableFilter === 'all'"
              dark
              large
              color="blue"
              class="text-capitalize font-weight-bold black--white mr-4 mt-2"
              :width="$vuetify.breakpoint.smAndDown ? '100%' : '150xp'"
              @click="addUsers"
            >
              {{ $t('projects.create_project.add') }} {{ toBeSelectedUserLength }}
            </v-btn>
            -->
            <v-btn
              v-if="showAddUsersView && toBeSelectedUserLength && tableFilter === 'selected'"
              dark
              large
              color="blue"
              class="text-capitalize font-weight-bold black--white mr-4 mt-2"
              :width="$vuetify.breakpoint.smAndDown ? '100%' : '150xp'"
              @click="removeUsers"
            >
              {{ $t('projects.create_project.remove') }} {{ toBeSelectedUserLength }}
            </v-btn>
            <v-btn
              v-if="!showAddUsersView || (showAddUsersView && !toBeSelectedUserLength)"
              dark
              large
              color="blue"
              :disabled="isCreateDisabled"
              class="text-capitalize font-weight-bold mt-2"
              :width="$vuetify.breakpoint.smAndDown ? '100%' : '150xp'"
              @click="createProject"
            >
              {{ $t('createProject') }}
            </v-btn>
          </v-flex>
        </v-col>
      </v-row>
    </v-card>
    <ProjectDiscardDialog
      :title="$t('projects.create_project.close_dialog.title')"
      v-model="showConfirmBackDialog"
      @close="handleCloseClick"
      @handleConfirmClick="handleConfirmClick"
    />
  </v-container>
</template>

<script>
import ProjectDiscardDialog from '@/components/Project/ProjectDiscardDialog.vue';
import ProjectUserTable from '@/components/Project/ProjectUserTable.vue';
import ProjectUserFilter from '@/components/Project/ProjectUserFilter.vue';
import { users } from '@/constants/data.js';
import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('project');
import { showErrorToast } from '@/utils/toast';

export default {
  name: 'ProjectCreate',
  components: {
    ProjectDiscardDialog,
    ProjectUserTable,
    ProjectUserFilter,
  },
  data() {
    return {
      isCreateDisabled: false,
      imageSrc: '',
      users: users,
      form: {
        projectName: '',
        projectKey: '',
        description: '',
        users: [],
      },
      showConfirmBackDialog: false,
      showAddUsersView: false,
      toBeSelectedUser: [],
      selectedUser: [],
      tableFilter: 'all',
      search: '',
      appliedFilters: {
        roles: [],
        projects: [],
        tags: [],
      },
      rules: {
        required: (value) => !!value || this.$t('required'),
        min2Chars: (v) => (v && v.length >= 2) || this.$t("min2Chars"),
        projectKeyMax10Chars: (v) => (v && v.length <= 10) || this.$t('projects.create_project.projectKeyMax10Chars'),
        projectKeyFormat: (v) => /^[A-Za-z0-9_-]+$/.test(v) || this.$t('projects.create_project.projectKeyFormat'),
      }
    };
  },
  computed: {
    imageStyle() {
      return this.imageSrc ? { backgroundImage: `url(${this.imageSrc})` } : {};
    },
    toBeSelectedUserLength() {
      return this.users.filter((user) => user.toBeSelected).length;
    },
    selectedUserLength() {
      return this.users.filter((user) => user.selected).length;
    },
    isAnyFilterApplied() {
      return this.appliedFilters.roles.length || this.appliedFilters.projects.length || this.appliedFilters.tags.length;
    },
  },
  mounted() {
    this.users.forEach((user) => {
      this.$set(user, 'toBeSelected', false);
    });
  },
  methods: {
    ...mapActions(['add']),
    createProject() {
      this.isCreateDisabled = true;
      const payload = {
        name: this.form.projectName,
        key: this.form.projectKey,
        customFields: {
          description: this.form.description,
          status: "active",
          star: true
        },
        source: 'testfiesta-web',
        externalId: 'testfiesta',
      };
      this.add({ swal: this.$swal, handle: this.$route.params.handle, payload })
        .then(() => {
          this.$router.push(`/${this.$route.params.handle}/projects`);
        })
        .catch(error => {
          showErrorToast(this.$swal, error?.response?.data?.message);
          console.error("Failed to create project:", error);
          this.isCreateDisabled = false;
        });
    },
    openFileDialog() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      this.previewImage(file);
    },
    handleDrop(event) {
      const file = event.dataTransfer.files[0];
      this.previewImage(file);
    },
    previewImage(file) {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    removeImage() {
      this.imageSrc = '';
    },
    handleBackClick() {
      if (this.showAddUsersView) {
        this.showAddUsersView = false;
      } else {
        this.showConfirmBackDialog = true;
      }
    },
    handleCloseClick() {
      this.showConfirmBackDialog = false;
    },
    handleConfirmClick() {
      this.showConfirmBackDialog = false;
      this.$router.replace({ name: 'ProjectsView' });
    },
    closeShowAddUsersView() {
      this.showAddUsersView = false;
      this.toBeSelectedUser = [];
    },
    changeFilter(filter) {
      this.tableFilter = filter;
    },
    addUsers() {
      this.users.forEach((user) => {
        if (user.toBeSelected) {
          this.$set(user, 'selected', true);
          this.$set(user, 'toBeSelected', false);
        }
      });
    },
    removeUsers() {
      this.users.forEach((user) => {
        if (user.toBeSelected) {
          this.$set(user, 'selected', false);
          this.$set(user, 'toBeSelected', false);
        }
      });
    },
    updateUser(user) {
      const index = this.users.findIndex((item) => item.id === user.id);
      if (index !== -1) {
        this.$set(user, 'selected', this.tableFilter === 'all');
        this.$set(user, 'toBeSelected', false);
      }
    },
    clearAll() {
      this.appliedFilters = {
        roles: [],
        projects: [],
        tags: [],
      };
    },
    applyFilters(filters) {
      this.appliedFilters = filters;
    },
  },
};
</script>

<style lang="scss" scoped>
.back-to-projects {
  display: flex;
  cursor: pointer;
  width: max-content;
}
.search_input {
  width: 100%;
  @media screen and (min-width: 600px) {
    width: 300px;
  }
}
.project-logo {
  border-radius: 50%;
  border: 2px dashed grey;
  width: 150px;
  height: 150px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  cursor: pointer;
  transition: border-color 0.3s;
  background-size: cover;
  background-position: center;
  position: relative;
}

.project-logo:hover {
  border-color: #2196f3;
}

.hovering .edit-icon,
.hovering .delete-icon {
  display: block;
}

.edit-icon,
.delete-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateX(-30px);
  display: none;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}
.delete-icon {
  margin-left: 60px;
}

.project-logo:hover .edit-icon,
.project-logo:hover .delete-icon {
  display: block;
}
</style>
